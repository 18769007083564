import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'about',
    meta: {
      keepAlive: true
    },
    component: () => import('@/views/home/index.vue')
  },
  {
    path: '/list',
    name: 'list',
    meta: {
      keepAlive: true
    },
    component: () => import('@/views/list/index.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('@/views/detail/index.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('@/views/search/index.vue')
  },
  {
    path: '/special',
    name: 'special',
    meta: {
      keepAlive: true
    },
    component: () => import('@/views/special/index.vue')
  },
  {
    path: '/special-list',
    name: 'special-list',
    meta: {
      keepAlive: true
    },
    component: () => import('@/views/special-list/index.vue')
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;
