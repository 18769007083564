<template>
  <div class="common-footer">
    <div class="row">
      <p>
        <a href="https://www.12377.cn/" target="_blank" style="margin-right: 30px;">
          <img src="@/assets/images/jubao1.jpg" alt="">
        </a>

        <a href="http://www.scjb.gov.cn/" target="_blank" style="margin-right: 30px;">
          <img src="@/assets/images/jubao4.jpg" alt=""></a>

        <a href="http://scpiyao.org.cn/" target="_blank" style="margin-right: 30px;">
          <img src="@/assets/images/zaoyao.png" alt=""></a>

        <a href="http://past.ybxww.com/gg/blxxjb/" target="_blank">
          <img src="@/assets/images/jubao5.jpg" alt="">
        </a>
      </p>
      <p>Copyright wwww.jaxrm.cn All Rights Reserved. 未经书面授权，不得复制或建立镜像</p>
      <p>新闻热线: 19881195566 媒体运营: 18881975588 行风监督: 0831-2628588 违法和不良信息举报电话: 0831-2637991 举报邮箱: 18881975588@139.com</p>
      <p>互联网新闻信息服务许可证: 51120200183 信息网络传播视听节目服务许可证: 1-23-4-2-0178 <img src="@/assets/images/gwb.png">川公网安备
        51152302000187号 地址: 江安县江安镇信合路95号 邮编: 644200
      </p>
      <p>主办单位名称: 江安县融媒体中心(江安县广播电视台) 主办单位性质: 事业单位</p>
      <p>备案/许可证号: <a href="https://beian.miit.gov.cn" target="_blank">蜀ICP备2020035609号</a></p>
    </div>
  </div>
</template>

<script setup lang="ts">

</script>

<style lang="less" scoped>
.common-footer {
  width: 100%;
  margin-top: 130px;
  padding-top: 20px;
  background: #efefef;
  text-align: center;

  padding-bottom: 15px;

  .row {
    zoom: 1;
    width: 1400px;
    margin: 0 auto;

    P {
      font-size: 14px;
      color: #3d3d3d;
      margin-bottom: 12px;
    }
  }
}
</style>