import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import ElementPlus from 'element-plus';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import 'element-plus/dist/index.css';
import './styles/element-theme.less';

import { createPinia } from "pinia";
const pinia = createPinia();

const app = createApp(App);
app.use(ElementPlus, { locale: zhCn });
app.use(router);
app.use(pinia);
app.mount('#app');
